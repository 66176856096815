import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from "@/components/ui/avatar";

import { Student } from "../types"

// Props for the Profile component
interface ProfileProps {
  student: Student;
}

const Profile: React.FC<ProfileProps> = ({ student }) => {
  const defaultImageUrl = "/path-to-default-image/default-profile.jpg"; // Placeholder for default image URL

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div>
        <Card>
          <CardContent className="flex items-center space-x-4">
            <Avatar>
              <AvatarImage src={student.image || defaultImageUrl} alt="@shadcn" />
              <AvatarFallback>CN</AvatarFallback>
            </Avatar>
            <div>
              <h1 className="text-xl font-bold">
                {student.first_name} {student.last_name}
              </h1>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Academy Progress</CardTitle>
          </CardHeader>
          <CardContent>
            <div>
              <Label htmlFor="progress">Progress</Label>
              <Input
                id="progress"
                type="text"
                defaultValue={`${student.progress}%`}
                disabled
              />
            </div>
            <div>
              <Label htmlFor="modules">Modules</Label>
              <Textarea
                id="modules"
                defaultValue={student.modules.join(", ")}
                disabled
              />
            </div>
            <div>
              <Label htmlFor="activeCourses">Active Courses</Label>
              <Textarea
                id="activeCourses"
                defaultValue={student.activeCourses.join(", ")}
                disabled
              />
            </div>
            <div>
              <Label htmlFor="notEnrolledCourses">Not Enrolled Courses</Label>
              <Textarea
                id="notEnrolledCourses"
                defaultValue={student.notEnrolledCourses.join(", ")}
                disabled
              />
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Career Plan</CardTitle>
          </CardHeader>
          <CardContent>
            <div>
              <Label htmlFor="linkedin">LinkedIn</Label>
              <Input
                id="linkedin"
                type="text"
                defaultValue={student.linkedin || ""}
              />
            </div>
            <div>
              <Label>Forms</Label>
              {/* {student.forms.map((form, index) => (
                <Button key={index} as="a" href={form.url} className="mt-2">
                  {form.name}
                </Button>
              ))} */}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Profile;
