import React, { useEffect, useState } from 'react';
import { Button } from "@/components/ui/button";
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { Course } from '@/types';

const Courses: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${import.meta.env.VITE_SERVER_URL}/api/courses`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        })

        if (!response.ok) {
          if (response.status === 403) {
            setError('Access forbidden: You do not have permission to view this resource.');
          } else {
            setError('An error occurred while fetching courses.');
          }
          setCourses([]);
          return;
        }

        const data = await response.json();
        setCourses(data);
      } catch (err) {
        setError('An error occurred while fetching courses.');
        setCourses([]);
      }
    };

    fetchCourses();
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Courses</CardTitle>
        <CardDescription>
          Explore and manage the courses offered by your institution.
        </CardDescription>
      </CardHeader>
      <CardContent>
        {error ? (
          <div className="text-red-500">{error}</div>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="hidden w-[100px] sm:table-cell"></TableHead>
                <TableHead>Title</TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {courses.map((course) => (
                <TableRow key={course.id}>
                  <TableCell className="hidden sm:table-cell">
                    <img
                      alt={`Image for ${course.title.rendered}`}
                      className="aspect-square rounded-md object-cover"
                      height={64}
                      width={64}
                    />
                  </TableCell>
                  <TableCell className="font-medium">
                    {course.title.rendered}
                  </TableCell>
                  <TableCell>
                    <Button variant="outline">
                      <Link to={course._links.users[0].href}>View Students</Link>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
      <CardFooter>
        <div className="text-xs text-muted-foreground">
          Showing <strong>{courses.length}</strong> courses
        </div>
      </CardFooter>
    </Card>
  );
}

export default Courses;