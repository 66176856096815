import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardHeader, CardContent, CardFooter, CardTitle, CardDescription } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/ui/table';
import { Dialog, DialogTrigger, DialogContent } from '@/components/ui/dialog';
import { UserIcon } from '@heroicons/react/24/outline';
import Profile from './Profile';

import { Student } from '@/types';

const Students: React.FC = () => {
  const [students, setStudents] = useState<Student[]>([]);
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch(`${import.meta.env.VITE_SERVER_URL}/api/students`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          if (response.status === 403) {
            setError('Access forbidden: You do not have permission to view this resource.');
          } else {
            setError('An error occurred while fetching students.');
          }
          setStudents([]);
          return;
        }

        const data = await response.json();
        setStudents(data);
      } catch (err) {
        setError('An error occurred while fetching students.');
        setStudents([]);
      }
    };

    fetchStudents();
  }, []);

  const handleEditClick = useCallback((student: Student) => {
    setSelectedStudent(student);
    console.log(selectedStudent)
  }, []);

  return (
    <Card className="">
      <CardHeader>
        <CardTitle className="text-2xl">Students</CardTitle>
        <CardDescription>
          Manage students and their academy progress.
        </CardDescription>
      </CardHeader>
      <CardContent>
        {error ? (
          <div className="text-red-500">{error}</div>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Slug</TableHead>
                <TableHead>Registered Date</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {students.map((student) => (
                <TableRow key={student.id}>
                  <TableCell>{`${student.first_name} ${student.last_name}`}</TableCell>
                  <TableCell>{student.email}</TableCell>
                  <TableCell>{student.slug}</TableCell>
                  <TableCell>{new Date(student.registered_date).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <Dialog>
                      <DialogTrigger>
                        <Button variant="outline" size="icon" onClick={() => handleEditClick(student)}>
                          <UserIcon className="h-5" />
                        </Button>
                      </DialogTrigger>
                      <DialogContent>
                        <Profile student={student} />
                      </DialogContent>
                    </Dialog>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
      <CardFooter>
        <div className="text-xs text-muted-foreground">
          Showing <strong>1-{students.length}</strong> of <strong>{students.length}</strong> students
        </div>
      </CardFooter>
    </Card>
  );
};

export default Students;
