import { Link } from "react-router-dom";
import {
  CircleUser,
  Search
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import Theme from "@/components/Navbar/Theme"
import { useAuth } from "@/context/AuthContext"
import { Sheet, SheetTrigger, SheetContent } from "@/components/ui/sheet";
import { Menu } from "lucide-react";

const Navbar = () => {
  const { logout } = useAuth();

  return (
    <div className="flex w-full flex-col">
      <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
        <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
          <Link
            to="/dashboard"
            className="flex items-center gap-2 text-lg font-semibold md:text-base"
          >
            <img className="h-max w-max" src="codecrypto.png" alt="Logo"></img>
            <span className="h-6 w-6"></span>
          </Link>
          <Link
            to="/dashboard"
            className="text-foreground transition-colors hover:text-foreground"
          >
            Dashboard
          </Link>
          <Link
            to="/students"
            className="text-muted-foreground transition-colors hover:text-foreground"
          >
            Students
          </Link>
          <Link
            to="/courses"
            className="text-muted-foreground transition-colors hover:text-foreground"
          >
            Bloques
          </Link>
          <Link
            to="/plan-de-carrera"
            className="text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap"
          >
            Plan de Carrera
          </Link>
          <Link
            to="#"
            className="text-muted-foreground transition-colors hover:text-foreground"
          >
            Other
          </Link>
        </nav>
        <Sheet>
          <SheetTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="shrink-0 md:hidden"
            >
              <Menu className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <nav className="grid gap-6 text-lg font-medium">
              <Link
                to="/dashboard"
                className="flex items-center gap-2 text-lg font-semibold"
              >
                <img className="h-10 w-10" src="codecrypto.png" alt="Logo"></img>
              </Link>
              <Link to="/dashboard" className="hover:text-foreground">
                Dashboard
              </Link>
              <Link
                to="/students"
                className="text-muted-foreground hover:text-foreground"
              >
                Students
              </Link>
              <Link
                to="/courses"
                className="text-muted-foreground hover:text-foreground"
              >
                Bloques
              </Link>
              <Link
                to="/plan-de-carrera"
                className="text-muted-foreground hover:text-foreground"
              >
                Plan de Carrera
              </Link>
              <Link
                to="#"
                className="text-muted-foreground hover:text-foreground"
              >
                Other
              </Link>
            </nav>
          </SheetContent>
        </Sheet>
        <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <form className="ml-auto flex-1 sm:flex-initial">
            <div className="relative">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                type="search"
                placeholder="Search products..."
                className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px]"
              />
            </div>
          </form>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon" className="rounded-full">
                <CircleUser className="h-5 w-5" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Settings</DropdownMenuItem>
              <DropdownMenuItem>Support</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={logout}>Logout</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        <Theme/>
        </div>
      </header>
    </div>
  );
}


export default Navbar;