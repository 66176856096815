import React, { useEffect, useState, useCallback } from "react";
import { Button } from "@/components/ui/button";

import { useToast } from "@/components/ui/use-toast";
import { ToastAction } from "@/components/ui/toast";
import { Card, CardContent, CardTitle, CardHeader, CardDescription } from "@/components/ui/card";
import { Calendar } from "@/components/ui/calendar";
import { format } from "date-fns";
import { Calendar as CalendarIcon, CalendarCheck2Icon, UploadIcon, ServerIcon, Loader2, X } from "lucide-react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { Checkbox } from "@/components/ui/checkbox";

import {
  Command,
  // CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";

import {
  Select,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

import { VideoFile, Student, Course, Lesson, VimeoProject } from "@/types";

const PlanCarrera: React.FC = () => {
  const [videos, setVideos] = useState<VideoFile[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);
  const [vimeoProjects, setVimeoProjects] = useState<VimeoProject[]>([]);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [date, setDate] = React.useState<Date | undefined>(undefined);
  const [selectedStudents, setSelectedStudents] = useState<(Student | null)[]>([]);
  const [selectedLessons, setSelectedLessons] = useState<(Lesson | null)[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<(Course | null)[]>([]);
  const [selectedVimeoProjects, setSelectedVimeoProjects] = useState<(VimeoProject | null)[]>([]);
  const { toast, dismiss } = useToast()

  function formatDateToSpanish(date: Date) {
    const months = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
  
    const day = date.getDate(); // Get the day as a number (1-31)
    const monthIndex = date.getMonth(); // Get the month as a number (0-11)
    const formattedDate = `${day < 10 ? '0' + day : day} ${months[monthIndex]}`; // Format to "DD MMMM"
  
    return formattedDate;
  }  

  useEffect(() => {
    const fetchVideos = async () => {
      const response = await fetch(`${import.meta.env.VITE_SERVER_URL}/api/videos/local`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      });
      if (!response.ok) {
        return;
      }
      const existingVideos = await response.json();
      setVideos(existingVideos.map((video: VideoFile) => ({
        ...video,
        isCheckpoint: false
      })));
      setUploadCompleted(false)
    };
    fetchVideos();
  }, [uploadCompleted]);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch(`${import.meta.env.VITE_SERVER_URL}/api/students`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          setStudents([]);
          return;
        }

        const data = await response.json();
        setStudents(data);
      } catch (err) {
        console.log(err)
        setStudents([]);
      }
    };

    fetchStudents();
  }, []);

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const response = await fetch(import.meta.env.VITE_SERVER_URL + '/api/database/get/lessons',
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
          }
        );

        if (!response.ok) {
          setLessons([]);
          return;
        }

        const data = await response.json();
        setLessons(data);
      } catch (err) {
        console.log(err);
        setLessons([]);
      }
    };
    fetchLessons();
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
      const response = await fetch(import.meta.env.VITE_SERVER_URL + "/api/database/get/courses", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      });
      if (!response.ok) {
        setCourses([]);
        return;
      }

      const data = await response.json();
      setCourses(data);
      } catch (err) {
        console.log(err);
        setCourses([])
      }
    };
    fetchCourses();
  }, []);

  useEffect(() => {
    const fetchVimeoProjects = async () => {
      try {
       const response = await fetch(import.meta.env.VITE_SERVER_URL + '/api/database/get/vimeo-projects',
       {
         headers: {
           Authorization: `Bearer ${localStorage.getItem("token")}`,
         }
       });

       if (!response.ok) {
         setVimeoProjects([]);
         return;
       }
       const data = await response.json();
       setVimeoProjects(data);
     } catch (err) {
      console.log(err);
      setVimeoProjects([]);
     }
    };
    fetchVimeoProjects();
  }, []);

  const uploadFiles = useCallback(async (files: File[]) => {
    if (files.length === 0) return;

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("videos", file);
      formData.append("filenames", file.name);  
    });

    const { id: uploadingToastId } = toast({
      title: "Uploading videos",
      description: (
        <div>
          <Loader2 className="animate-spin" />
          Please wait patiently, the videos are being uploaded to the cloud.
        </div>
      ),
      duration: 2000,
    });

    const response = await fetch(import.meta.env.VITE_SERVER_URL + "/api/videos/upload", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Accept-Charset": "utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      body: formData,
    });

    dismiss(uploadingToastId);

    if (response.ok) {
      setUploadCompleted(true);
      toast({
        title: "Videos uploaded successfully!",
        description: "Double check before uploading them to Wordpress.",
        duration: 2000
      });
    } else {
      toast({
        variant: "destructive",
        title: "Upload Error!",
        description: "An error occurred during the file upload.",
        action: <ToastAction altText="Try again">Try Again</ToastAction>,
        duration: 2000,
      });
    }
  }, [toast, dismiss]);

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileArray = Array.from(event.target.files);
      // const newVideos = fileArray.map(file => ({
      //   title: file.name,
      //   size: file.size,
      //   created: new Date().toISOString(),
      //   path: '',
      // }));
      toast({
        title: "Uploading videos",
        description: (
          <div>
            <Loader2 className=" animate-spin" />
            Please wait patiently, the videos are being uploaded to the cloud.
            <div className="spinner" />{" "}
          </div>
        ),
        duration: 2000,
      });
      uploadFiles(fileArray);
      // setVideos(prev => [...prev, ...newVideos]);
    }
  }, [uploadFiles, toast]);
 
  const handleUploadToWordPress = async () => {
    const videosWithOptions = videos.map((video, index) => ({
      ...video,
      studentId: selectedStudents[index]?.id,
      lessonId: selectedLessons[index]?.id,
      courseId: selectedCourses[index]?.id,
      vimeoPath: selectedVimeoProjects[index]?.id
    }));

    const hasCheckpointVideoWithoutDate = videosWithOptions.some(
      (video) => video.isCheckpoint && !date
    );

    if (!videos.length || hasCheckpointVideoWithoutDate) {
      alert(!date ? "Date was not provided!" : "No videos to upload!");
      return;
    }  
        const formattedDate = !date ? undefined : formatDateToSpanish(date);   

        toast({
          title: "Uploading videos",
          description: (
            <div>
              <Loader2 className=" animate-spin" />
              Please wait patiently, the videos are being processed.
              <div className="spinner" />{" "}
            </div>
          ),
          duration: 30 * 1000,
        });

    const response = await fetch(import.meta.env.VITE_SERVER_URL + "/api/videos/wp-upload", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      body: JSON.stringify({ params: { date: formattedDate, videos: videosWithOptions } }),
    });

    if (response.ok) {
      setVideos([]);
      setSelectedCourses([]);
      setSelectedLessons([]);
      setSelectedStudents([]);
      setSelectedVimeoProjects([]);
      toast({
        title: "Completed!",
        description: "The videos have been successfully uploaded to WordPress.",
        duration: 2000, // Auto-close after 5 seconds
      });
    } else {
      toast({
        variant: "destructive",
        title: "Wordpress upload error!",
        description: "An error occured during the file upload.",
        action: <ToastAction altText="Try again">Try Again</ToastAction>,
        duration: 2000,
      });
    }
  };

  const handleRemoveVideo = async (videoTitle: string) => {
    const requestBody = {
      params: { videoTitle }
    }
    const response = await fetch(import.meta.env.VITE_SERVER_URL + "/api/videos/remove", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(requestBody)
    });

    if (response.ok) {
      setVideos(prev => prev.filter(video => video.title !== videoTitle))
      toast({
        title: "Video deleted successfully!",
        description: "",
        duration: 1000
      });
    } else {
      toast({
        variant: "destructive",
        title: "Deletion Error!",
        description: "An error occurred while trying to delete the video.",
        // action: <ToastAction altText="Try again">Try Again</ToastAction>,
        duration: 2000,
      });
    }    
  }

  const handleStudentSelect = (videoIndex: number, student: Student) => {
    setSelectedStudents(prev => {
      const updated = [...prev];
      updated[videoIndex] = student;
      return updated;
    });
  };

  const handleLessonsSelect = (lessonIndex: number, lesson: Lesson) => {
    setSelectedLessons(prev => {
      const updated = [...prev];
      updated[lessonIndex] = lesson;
      return updated;
    });
  };

  const handleCoursesSelect = (courseIndex: number, course: Course) => {
    setSelectedCourses(prev => {
      const updated = [...prev];
      updated[courseIndex] = course;
      return updated;
    });
  };

  const handleVimeoProjectsSelect = (vimeoProjectIndex: number, vimeoProject: VimeoProject) => {
    setSelectedVimeoProjects(prev => {
      const updated = [...prev];
      updated[vimeoProjectIndex] = vimeoProject;
      return updated;
    });
  };

  const handleCheckpointChange = (videoIndex: number, isChecked: boolean) => {
    setVideos(prev => {
      const updated = [...prev];
      updated[videoIndex].isCheckpoint = isChecked;
      return updated;
    });
  };

  function truncateMiddle(text: string | undefined, maxLength: number) {
    if (!text) return '';
    if (text.length <= maxLength) {
      return text;
    }

    return text.substring(0, maxLength - 3) + '...';

    // const separator = "...";
    // const charsToShow = maxLength - separator.length;
    // const frontChars = Math.ceil(charsToShow / 2);
    // const backChars = Math.floor(charsToShow / 2);

    // return (
    //   text.substring(0, frontChars) +
    //   separator +
    //   text.substring(text.length - backChars)
    // );
  }

  return (
    <div className="flex min-h-screen w-full flex-col">
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <Card
          className="flex items-center justify-center p-6 rounded-lg border border-dashed border-2 shadow-sm"
          onClick={() => {
            const fileInput = document.getElementById("video-file-upload");
            if (fileInput) {
              fileInput.click();
            } else {
              console.error("File input not found");
            }
          }}
        >
          <CardContent className="flex flex-col items-center gap-1 text-center">
            <UploadIcon className="h-8 w-8" />
            <div className="">
              <h3 className="text-xl font-bold tracking-tight">
                Click to upload videos
              </h3>
              <p className="text-sm text-muted-foreground">
                You can upload more than one video simultaneously
              </p>
            </div>
          </CardContent>
          <input
            id="video-file-upload"
            type="file"
            multiple
            accept="video/mp4"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </Card>
        <Card className="flex flex-col items-center justify-center p-6 rounded-lg border shadow-sm">
          <CardHeader className="w-full flex flex-col items-start justify-start p-4">
            <CardTitle>Uploaded Videos</CardTitle>
            <CardDescription>
              The list of videos ready to be uploaded
            </CardDescription>
          </CardHeader>
          <CardContent className="w-full">
            <Table>
              <TableCaption>
                <ServerIcon className="h-8 w-8"></ServerIcon>
              </TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-100">Title</TableHead>
                  <TableHead>Timestamp</TableHead>
                  <TableHead className="">Size</TableHead>
                  <TableHead>Student</TableHead>
                  <TableHead>Bloque</TableHead>
                  <TableHead>Module</TableHead>
                  <TableHead>Vimeo Folder</TableHead>
                  <TableHead>
                    <CalendarCheck2Icon className="h-4 w-4" />
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {videos.map((video, index) => (
                  <TableRow key={index}>
                    <TableCell className="font-medium">{video.title}</TableCell>
                    <TableCell className="">
                      {new Date(video.created).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      - {new Date(video.created).toLocaleDateString()}
                    </TableCell>
                    <TableCell className="">
                      {(video.size / (1024 * 1024)).toFixed(2)} MB
                    </TableCell>
                    <TableCell>
                      <Popover>
                        <PopoverTrigger>
                          {selectedStudents[index] ? (
                            <Button
                              variant="ghost"
                              className="w-48 overflow-hidden whitespace-nowrap text-ellipsis"
                            >
                              {truncateMiddle(
                                selectedStudents[index]?.name,
                                20
                              ) || ""}
                            </Button>
                          ) : (
                            <Select>
                              <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Select student" />
                              </SelectTrigger>
                            </Select>
                          )}
                        </PopoverTrigger>
                        <PopoverContent>
                          <Command>
                            <CommandInput placeholder="Type the student name..." />
                            <CommandList>
                              <CommandEmpty>No results found.</CommandEmpty>
                              <CommandGroup heading="Students">
                                {students.map((student) => (
                                  <CommandItem
                                    key={student.id}
                                    onSelect={() =>
                                      handleStudentSelect(index, student)
                                    }
                                  >
                                    {student.name}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </TableCell>
                    <TableCell>
                      <Popover>
                        <PopoverTrigger>
                          {selectedCourses[index] ? (
                            <Button
                              variant="ghost"
                              className="w-48 overflow-hidden whitespace-nowrap text-ellipsis"
                            >
                              {truncateMiddle(
                                selectedCourses[index]?.title.rendered,
                                20
                              ) || ""}
                            </Button>
                          ) : (
                            <Select>
                              <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Select bloque" />
                              </SelectTrigger>
                            </Select>
                          )}
                        </PopoverTrigger>
                        <PopoverContent>
                          <Command>
                            <CommandInput placeholder="Type the course name..." />
                            <CommandList>
                              <CommandEmpty>No results found.</CommandEmpty>
                              <CommandGroup heading="Courses">
                                {courses.map((course) => (
                                  <CommandItem
                                    key={course.id}
                                    onSelect={() =>
                                      handleCoursesSelect(index, course)
                                    }
                                  >
                                    {course.title.rendered}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </TableCell>
                    <TableCell>
                      <Popover>
                        <PopoverTrigger>
                          {selectedLessons[index] ? (
                            <Button
                              variant="ghost"
                              className="w-48 overflow-hidden whitespace-nowrap text-ellipsis"
                            >
                              {truncateMiddle(
                                selectedLessons[index]?.title.rendered,
                                20
                              ) || ""}
                            </Button>
                          ) : (
                            <Select>
                              <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Select module" />
                              </SelectTrigger>
                            </Select>
                          )}
                        </PopoverTrigger>
                        <PopoverContent>
                          <Command>
                            <CommandInput placeholder="Type the student name..." />
                            <CommandList>
                              <CommandEmpty>No results found.</CommandEmpty>
                              <CommandGroup heading="Students">
                                {lessons.map((lessons) => (
                                  <CommandItem
                                    key={lessons.id}
                                    onSelect={() =>
                                      handleLessonsSelect(index, lessons)
                                    }
                                  >
                                    {lessons.title.rendered}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </TableCell>
                    <TableCell>
                      <Popover>
                        <PopoverTrigger>
                          {selectedVimeoProjects[index] ? (
                            <Button
                              variant="ghost"
                              className="w-48 overflow-hidden whitespace-nowrap text-ellipsis"
                            >
                              {truncateMiddle(
                                selectedVimeoProjects[index]?.name,
                                20
                              ) || ""}
                            </Button>
                          ) : (
                            <Select>
                              <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Select vimeo folder" />
                              </SelectTrigger>
                            </Select>
                          )}
                        </PopoverTrigger>
                        <PopoverContent>
                          <Command>
                            <CommandInput placeholder="Type the vimeo name..." />
                            <CommandList>
                              <CommandEmpty>No results found.</CommandEmpty>
                              <CommandGroup heading="VimeoPaths">
                                {vimeoProjects.map((vimeoProjects) => (
                                  <CommandItem
                                    key={vimeoProjects.id}
                                    onSelect={() =>
                                      handleVimeoProjectsSelect(index, vimeoProjects)
                                    }
                                  >
                                    {vimeoProjects.name}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={video.isCheckpoint}
                        onCheckedChange={(isChecked: boolean) =>
                          handleCheckpointChange(index, isChecked)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="ghost"
                        onClick={() => handleRemoveVideo(video.title)}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        {videos.length > 0 && (
          <div className="flex items-center justify-center gap-4">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={"w-[280px] justify-start text-left font-normal"}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {date ? format(date, "PPP") : <span>Pick a date</span>}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="single"
                  selected={date}
                  onSelect={setDate}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
            <Button onClick={handleUploadToWordPress}>
              Upload to WordPress
            </Button>
          </div>
        )}
      </main>
    </div>
  );
};

export default PlanCarrera;
