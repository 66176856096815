import React from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from "@/context/AuthContext";
import { ThemeProvider } from "@/context/ThemeProvider";

import LoginPage from '@/components/LoginPage';
import Layout from '@/components/Layout'
import Dashboard from '@/pages/Dashboard';
import Students from "@/pages/Students";
import Courses from "@/pages/Courses";
import PlanCarrera from "@/pages/PlanCarrera"

const App: React.FC = () => {
  const { user } = useAuth();

  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <Router>
        <Routes>
          <Route
            path="/"
            element={!user ? <LoginPage /> : <Navigate to="/dashboard" />}
          />
          <Route
            path="/dashboard"
            element={user ? <Layout><Dashboard /></Layout> : <Navigate to="/" />}
          />
          <Route
            path="/students"
            element={user ? <Layout><Students /></Layout> : <Navigate to="/" />}
          />
          <Route
            path="/courses"
            element={user ? <Layout><Courses /></Layout> : <Navigate to="/" />}
          />
          <Route
            path="/plan-de-carrera"
            element={user ? <Layout><PlanCarrera /></Layout> : <Navigate to="/" />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;