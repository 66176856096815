import React, { ReactNode } from 'react';
import Navbar from '@/components/Navbar/Navbar';
import { Toaster } from "@/components/ui/toaster"

type LayoutProps = {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <div>
            <Navbar />
            <main>{children}</main>
            <Toaster />
        </div>
    )
}

export default Layout;